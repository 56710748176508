import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Glide from "@glidejs/glide"

// Stylesheets
import "@glidejs/glide/dist/css/glide.core.min.css"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ButtonLink } from "components/buttons"

const StyledProductSpecifications = styled.section`
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding-bottom: 50px;
	border-bottom-width: 0;
	min-height: 650px;
	background-color: ${colors.grayLight};
	flex-direction: column;

    ${breakpoint.medium`
		padding-bottom: 180px;
    `}

	.glide {
		width: 100%;
		margin-top: 0px;

		${breakpoint.medium`
			width: 44%;
			margin-top: 320px;
		`}
	}

  .bullets {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2rem 0px 1rem;
		
	 &.glide__bullets {
		  position: relative;
		  top: 0;
		  margin-left: 0px;

			${breakpoint.medium`
		  		top: 120px;
				margin-left: 50px;
				position: absolute;
			`}

		}
	  }
	
	.bullet {
	  width: 13px;
	  height: 13px;
	  position: relative;
	  display: flex;
	  margin: 0px 3px 0px 4px;
	  border-radius: 50%;
	  transition: all 0.3s;
	  cursor: pointer;

	  &::before {
		content: "";
		width: 7px;
		height: 7px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		border: 1px solid ${colors.orange};
		border-radius: 50%;
	  }

	  &:hover,
	  &.glide__bullet--active {
		background-color: ${colors.orange};
	  }

	  &:last-child {
		margin-right: 0;
	  }
	}

	h2 {
	  position: revert;
	  margin: 120px auto 0;
	  text-align: center;

		${breakpoint.medium`
			margin: 200px 40px 0;
			position: absolute;
			text-align: left;
		`}
	}
  
	.break__sections {
		display: flex;
		flex-wrap: wrap;
		column-gap: 50px;

	${breakpoint.medium`
		flex-wrap: nowrap;
	`}
	}

	${Container} {
		display: block;
		max-width: 1100px;
		
		${breakpoint.medium`
			display: flex;
			flex-flow: row;
			column-gap: 50px;
		`}
		
	  }

    .respiratoryIllustration {
    	display: none;
		width: 100%;
		height: auto;
		margin: auto;
		margin-top: 5px;
		max-width: 500px;

		${breakpoint.small`
			margin: auto;
			margin-top: 10px;
			max-width: 650px;
		`}

		${breakpoint.medium`
			margin-top: 155px;
		`}
    }
    
    .respiratoryIllustration1 { display: block; }
    
	.glide__slides { 
		transform:none !important; 
		width:auto !important;
		display: grid; 
		grid-template-areas: 'slide'; 
	}
	.glide__slide {
		position: relative; 
		opacity: 0; 
		transition: opacity 0.1s ease; 
		grid-area: slide; 
		margin: 0 !important;

		&--active {
			z-index:1; 
			opacity:1;
		}		
	}

	${ButtonLink} {
        width: auto;
		margin-top: 25px;
		color: ${colors.charcoal}; 

		&:hover {
			color: ${colors.white}; 
		}
    }

	.howToBuyButton {
		margin-top: 10px;

		${breakpoint.small`
		margin-top: 60px;
		`}
	}
    
`


const StyledIllustrationStep = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    place-content: center;
    padding: 0px;
    margin: 0 auto;

    ${breakpoint.medium`
		margin: 0px;
    `}

  .break__content {
    position: relative;
    margin-bottom: 0;
    margin-left: 0px;
    z-index: 10;
    flex-basis: 500px;
    flex-grow: 1;
    flex-shrink: 1;
    
    ${breakpoint.medium`
    	flex-basis: 300px;
		margin-left: 40px;
		margin-bottom: 20px;
    `}
    }
      
	h3 {
	  padding-top: 30px;
	  text-align: center;
	  font-weight: 600;
	  font-size: 18px;
	  margin: 0;
		
    ${breakpoint.medium`
		text-align: left;
    `}
	}

	p { 
		text-align: center;
		font-size: 17px;
		line-height: 1.6rem;
		width: 300px;

		${breakpoint.medium`
			text-align: left;
			width: 350px;
		`}
	}
 	 	
`


const IllustrationStep = props => (
  <StyledIllustrationStep>
    <div className="break__content">
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </div>
  </StyledIllustrationStep>
)

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  componentDidMount = () => {
  
    const carousel = new Glide(this.myRef.current, {
		type: "carousel",
		autoplay: 3500,
		animationDuration: 0,
		animationTimingFunc: 'ease-in',
		perView: 1
    }    )
    
    carousel.mount()

	 carousel.on(['mount.after', 'run'], function () {
		 if( document.querySelector('.respiratoryIllustration1') != null) {
			document.querySelector('.respiratoryIllustration1').style.display="none";
			document.querySelector('.respiratoryIllustration2').style.display="none";
			document.querySelector('.respiratoryIllustration3').style.display="none";
			document.querySelector('.respiratoryIllustration' + (carousel.index+1)).style.display="block";
		}
	} )
   
  }

  render = props => (
    <div className="glide" ref={this.myRef}>
      <div className="glide__track" data-glide-el="track">
        <div className="glide__slides">{this.props.children}</div>
		  <div className="bullets glide__bullets" data-glide-el="controls[nav]">
			<button type="bullet" aria-label="Illustration 1" className="bullet" data-glide-dir="=0"></button>
			<button type="bullet" aria-label="Illustration 2" className="bullet" data-glide-dir="=1"></button>
			<button type="bullet" aria-label="Illustration 3" className="bullet" data-glide-dir="=2"></button>
		  </div>
      </div>
    </div>
  )
}

const ProductSpecifications = () => {

  return (  
  <StyledProductSpecifications>
      <Container>
      	<h2 data-aos="fade-up" data-aos-once="false">Powerful and easy<br />to use, anywhere</h2>
        <Carousel>
            <div className="glide__slide">
				<div className="break__sections">
					<IllustrationStep
						title="LED Status Lights"
						description="Power, progress and ready lights indicate the status of the test."
						key="step1"
					/>
				</div>
			</div>
            <div className="glide__slide">
				<div className="break__sections">
					<IllustrationStep
						title="Sample Port"
						description="Add the sample and slide the purple switch to run the test."
						key="step2"
					/>
				</div>
			</div>
            <div className="glide__slide">
				<div className="break__sections">
					<IllustrationStep
						title="Test Control"
						description="A clear, identifying spot that indicates test results are valid."
						key="step3"
					/>
				</div>
            </div>
       </Carousel>
	   <StaticImage
			src="../../../assets/images/respiratory-health-test/specifications/device1.png"
			width={750}
			alt="True PCR technology"
			className="respiratoryIllustration respiratoryIllustration1"
			formats={["webp"]}
		/>
	   <StaticImage
			src="../../../assets/images/respiratory-health-test/specifications/device2.png"
			width={750}
			alt="Sample Port"
			className="respiratoryIllustration respiratoryIllustration2"
			formats={["webp"]}
			/>
	   <StaticImage
			src="../../../assets/images/respiratory-health-test/specifications/device3.png"
			width={750}
			alt="Test control"
			className="respiratoryIllustration respiratoryIllustration3"
			formats={["webp"]}
		/>
      </Container>
	  <div className="howToBuyButton"><ButtonLink backgroundcolor="primary" to="/how-to-buy/" data-aos="fade-up">Order Now</ButtonLink></div>
    </StyledProductSpecifications>
  )
}

export default ProductSpecifications